import React, { useContext, useMemo } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import RawHtml from 'utils/miscUtils/rawHtml';
import { scrubContent } from 'utils/stringUtils';
import { Article, ArticlePreview } from 'interfaces/content/articles/Articles';
import ContentWithEmbed from 'components/ContentWithEmbed';
import PresentedBy, { PresentedByProps } from 'components/PresentedBy';
import AdminBar from 'components/AdminBar';
import EditorialDisclosure from 'components/EditorialDisclosure/EditorialDisclosure';
import { UserPaywallContext } from 'providers/PaywallProvider';
import ContentWithRVWidgets from 'components/ContentWithRVWidgets';
import { Microsites, RedVentureWidgets, subdomainColors } from 'constants/constants';
import { UserContext } from 'providers/UserProvider';
import { EventData } from 'services/Gtm/entities';
import { Hero } from 'components/ArticleV2/HeroV2';
import LatestPopularContent from 'components/ArticleV2/Latest';
import getArticlePageTestId from 'constants/testsIds/articlePage';
import InStream from 'components/Ad/AdSlot/InStream';
import TrinityPlayer from 'components/TrinityPlayer';
import { PageType } from 'interfaces/content/articles/Post';
import {
  ArticleContentContainer,
  ArticleContentWrapper,
  ArticleImageAndContentContainer,
  BreadcrumbsContainer,
  Container,
  FooterStyles,
  TrinityPlayerContainer,
} from './ArticleStyles';

export interface ArticleProps {
  article: Article;
  presentedBy?: PresentedByProps;
  footer?: JSX.Element[];
  adFrequency: number;
  embeds: { node: string; index: number; component: JSX.Element }[];
  isEditorialDisclosureVisible?: boolean;
  lastPWithoutAds?: number;
  subdomain: string;
  microsite?: string;
  stickyTop?: number | null;
  zipCode?: string;
  latestPosts: {
    posts: ArticlePreview[];
  };
  mostPopular: {
    posts: ArticlePreview[];
  };
  noRecirculationModules?: boolean;
  noTrinityAds: boolean;
}

const ArticlePage: React.FC<ArticleProps> = ({
  article,
  presentedBy,
  footer,
  adFrequency,
  embeds,
  isEditorialDisclosureVisible,
  lastPWithoutAds,
  subdomain,
  microsite,
  stickyTop = null,
  zipCode,
  latestPosts,
  mostPopular,
  noRecirculationModules,
  noTrinityAds,
}) => {
  const { breadcrumbs, content, databaseId } = article;

  const hideLatestPopularModule = noRecirculationModules || false;

  const cleanedContent = scrubContent(content);
  const widgetsInArticle = useMemo(
    () => Object.values(RedVentureWidgets).filter((id) => content.indexOf(id) > -1),
    [content],
  );

  const { paywallCheck } = useContext(UserPaywallContext).paywallState;
  const { userState: user } = useContext(UserContext);

  const RawHtmlClass = paywallCheck === 'no-paywall' ? 'rawHtml-content' : 'rawHtml-content-no-nativo';

  const primarySection = {
    link: article.primarySection.uri,
    name: article.primarySection.name,
  };

  const breadcrumbsData = <Breadcrumbs {...breadcrumbs} />;

  const hasFeaturedMediaTypeVideo =
    article.featuredMediaType === 'fortune_video' || article.featuredMediaType === 'stn_video_media';

  const trackingData: EventData = {
    HasVideoCD: hasFeaturedMediaTypeVideo,
    articleNameCD: article.title,
    authorNameCD: article.authorNames,
    cmsCategoryCD: 'article-v2',
    cmsSubCategoryCD: article.primarySection.name,
    contentCMSID: article.postId,
    contentPublishedDateCD: article.dateGmt,
    contentTagsCD: article.tagNames,
    hitIDCD: '',
    loginStatusCD: user.isLoggedIn ? 'logged-in' : 'guest',
    platformCD: article.platformCD || 'own',
    premiumCategoryCD: article?.premiumCategory.name || '',
    sessionIDCD: '',
    userAgentCD: typeof window !== 'undefined' ? window.navigator?.userAgent : '',
  };

  const widgetsTrackingData: EventData = {
    ...trackingData,
    contentPlacementCD: 'Recommends Widget',
  };

  const latestModuleTrackingData: EventData = {
    ...trackingData,
    contentPlacementCD: 'latest - bottom',
    eventAction: 'latest module click',
    eventCategory: 'navigation',
  };

  let latestTitle = '';
  if (microsite === Microsites.Crypto) {
    latestTitle = 'Latest in Crypto';
  } else if (primarySection.name === 'Magazine') {
    latestTitle = `Latest from the ${primarySection.name}`;
  } else if (primarySection.name === 'Conferences') {
    latestTitle = `Latest from our ${primarySection.name}`;
  } else {
    latestTitle = `Latest in ${primarySection.name}`;
  }

  return (
    <Container
      id='content'
      role='article'
    >
      {presentedBy && <PresentedBy {...presentedBy} />}
      {Microsites.Recommends === microsite && <BreadcrumbsContainer> {breadcrumbsData} </BreadcrumbsContainer>}
      <AdminBar pageId={databaseId} />
      <Hero article={article} />
      <TrinityPlayerContainer className='trinity-player'>
        <TrinityPlayer
          url={article.url}
          noTrinityAds={noTrinityAds}
          paywallCheck={paywallCheck}
          microsite={subdomain}
        />
      </TrinityPlayerContainer>
      <ArticleContentContainer>
        <ArticleImageAndContentContainer>
          <ArticleContentWrapper
            id='article-content'
            $subdomain={subdomain}
            $stickyTop={stickyTop}
            $color={subdomainColors[subdomain]}
          >
            <ContentWithRVWidgets
              widgetIds={widgetsInArticle}
              microsite={subdomain}
              zipCode={zipCode}
              trackingData={widgetsTrackingData}
              postId={article.postId}
            >
              <ContentWithEmbed
                embedInstructions={embeds.map((embed) => ({ index: embed.index, node: embed.node }))}
                adFrequency={adFrequency}
                lastPWithoutAds={lastPWithoutAds}
                pageType={PageType.ARTICLE_V2}
              >
                {[
                  ...embeds.map((embed) => embed.component),
                  <div
                    key={`article-content-${adFrequency}`}
                    data-cy={getArticlePageTestId('CONTENT')}
                    className={RawHtmlClass}
                  >
                    {RawHtml({ html: cleanedContent })}
                  </div>,
                ]}
              </ContentWithEmbed>
            </ContentWithRVWidgets>
            {isEditorialDisclosureVisible && <EditorialDisclosure />}
            <InStream
              index={100}
              renderAds
            />
            {primarySection.name !== 'Commentary' && footer && <FooterStyles>{footer}</FooterStyles>}
          </ArticleContentWrapper>
          {!hideLatestPopularModule && (
            <>
              {latestPosts && (
                <LatestPopularContent
                  content={latestPosts}
                  trackingData={latestModuleTrackingData}
                  title={latestTitle}
                />
              )}
              <InStream
                index={101}
                renderAds
              />
              {mostPopular && (
                <LatestPopularContent
                  content={mostPopular}
                  trackingData={latestModuleTrackingData}
                  title='Most Popular'
                />
              )}
              {mostPopular.posts.length > 0 && (
                <InStream
                  index={102}
                  renderAds
                />
              )}
            </>
          )}
        </ArticleImageAndContentContainer>
      </ArticleContentContainer>
    </Container>
  );
};

export { ArticlePage };
